import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import '../Account/_account-page.scss';
import '../../Styles/dashboard.scss';
import './actions.scss';
import DashboardHeader from './DashboardComponents/DashboardHeader';
import { getRole } from '../../_helpers/user.perms';
import JourneyPendingOrders from './DashboardComponents/JourneyPendingOrders';
import TodaysAppointmentsCard from './DashboardComponents/TodaysAppointment';
import PerformanceTrackerCard from './DashboardComponents/PerformanceTracker';
import "react-toggle/style.css"
import { DashboardAvailability } from './DashboardAvailability';
import { tenantService } from '../../_services/tenant.service';
import { Feature } from '../../_types';
import { ConnectActions } from './ConnectActions';

export default function Dashboard() {
    const user = useSelector(state => state.authentication.user);
    const tenantId = user.activeTenant.id;

    const [role, setRole] = useState();
    const [features, setFeatures] = useState([]);
    const [setupActions, setSetupActions] = useState();

    useEffect(() => {
        if (tenantId) {
            tenantService.getTenantById(tenantId).then(t => setSetupActions(!t.new))
        }
    }, [tenantId])

    useEffect(() => {
        setRole(getRole(user));
        tenantService.getFeatures().then(setFeatures);
    }, []);

    function CardTitle(props) {
        return <div className="card-subtitle">{props.children}</div>
    }

    return (
        <div className="dashboard-container">
            {setupActions ?
                <>
                    <div className='dashboard-block'>
                        <div className='card h-100'>
                            <div className='card-body'>
                                <DashboardHeader role={role} features={features} />
                            </div>
                        </div>
                    </div>
                    <div className='dashboard-block'><DashboardAvailability user={user} /></div>
                    <div className='dashboard-block'>
                        <TodaysAppointmentsCard CardTitle={CardTitle} />
                    </div>
                    <div className='dashboard-block'>
                        <PerformanceTrackerCard CardTitle={CardTitle} />
                    </div>
                    {features && features.includes(Feature.Orders) &&
                        <div className='dashboard-block'>
                            <JourneyPendingOrders CartTitle={CardTitle} />
                        </div>
                    }
                </>
                :
                <ConnectActions />
            }
        </div>
    );
}
