import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import '../../Styles/_components/_login.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { alertActions } from '../../_actions';


export function SignupForm() {
    const { t } = useTranslation("common");
    const auth = useSelector(state => state.authentication);
    const alerts = useSelector(state => state.alert);
    const [alert, setAlert] = useState({});

    useEffect(() => {
        if (alerts && alerts.queue.length) {
            setAlert(alerts.queue[0]);
            setShowModal(true);
            setLoading(false);
        }
    }, [alerts.queue]);

    const clearAlert = () => {
        setAlert({});
        dispatch(alertActions.clear());
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({
        name: '',
        email: '',
    });
    const { name, email } = inputs;
    const [submitted, setSubmitted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    function signup(e) {
        e.preventDefault();
        setSubmitted(true);
        setLoading(true);
        if (name && email) {
            dispatch(userActions.addExternalUser({ name, email }, navigate));
        } else {
            setLoading(false);
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleClose() {
        setShowModal(false);
        clearAlert();
    }

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
            handleClose();
        }
    };

    return (
        <div className="login-form">
            <div className="login-logo">
                <div className='welcome'>{t("signup.signup-title")}</div>
                <div className='welcome-msg'>{t("signup.signup-subtitle")}</div>
            </div>
            <form className="form" onSubmit={signup}>
                <div className="form-group">
                    <label>{t("signup.admin-name")}</label>
                    <input
                        data-testid="name"
                        type="text"
                        name="name"
                        value={name}
                        placeholder={t("signup.full-name-placeholder")}
                        onChange={handleChange}
                        className={'form-control' + (submitted && !name ? ' is-invalid' : '')}
                    />
                    {submitted && !name ? <span className='required-field'>{t("signup.required-field")}</span> : null}
                </div>
                <div className="form-group">
                    <label>{t("signup.email")}</label>
                    <input
                        data-testid="email"
                        type="text"
                        name="email"
                        value={email}
                        placeholder={t("signup.email-placeholder")}
                        onChange={handleChange}
                        className={'form-control' + (submitted && !email ? ' is-invalid' : '')}
                    />
                    {submitted && !name ? <span className='required-field'>{t("signup.required-field")}</span> : null}

                </div>
                <div className="form-group">
                    <button data-testid="signup" className="btn btn-primary">
                        {loading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        {t("signup.create-account")}
                    </button>
                </div>
            </form>
            {showModal && (
                <div className="modal-overlay" onClick={handleOverlayClick}>
                    <div className="confirmation-modal login" show={showModal}>
                        <div className='close-confirmation-modal close' onClick={handleClose}><i className="far fa-times"></i></div>
                        <div className="confirmation-modal-header">{alert.message.status === 409 ? t("signup.alert-email") : t("signup.confirm-email")}</div>
                        <div className="confirmation-message">{alert.message.status === 409 ? t("signup.alert-email-message") : t("signup.confirm-email-message")}</div>
                        <div className="modal-footer">
                            <div className="confirmation-modal-buttons">
                                <a href='/reset' className="btn btn-primary w-50">{t("signup.reset")}</a>
                                <a href='/login' className="btn btn-primary w-50">{t("signup.login")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
