import { AccountData, Feature, PagingList, SetupOptions, Tenant, TenantUpdate } from '../_types';
import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'
import { ApiOptions, apiService } from './api.service';

export const tenantService = {
    searchTenants,
    deleteTenant,
    updateTenant,
    addTenant,
    getTenantById,
    getAccountUsage,
    getHost,
    getFeatures,
    setupTenant
}

function getTenantById(id: number, includes: string): Promise<Tenant> {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    var url = `${config.apiUrl}/tenants/${id}?`;
    if (includes) {
        url += `include=${includes}`
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(tenants => {
            return tenants
        })
}
export class FeatureSet {
    features: number;

    constructor(features: number) {
        this.features = features;
    }

    includes(feature: Feature): boolean {
        return (this.features & feature) === feature;
    }    

    static Empty = new FeatureSet(0);
}

async function getFeatures(): Promise<FeatureSet> {
    return await getAccountUsage().then((data) => new FeatureSet(data.features))
}

function getHost(host: string) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/tenants/home?s=${host}`, requestOptions)
        .then(handleResponse)
        .then(tenants => {
            return tenants
        })
}

function searchTenants(options: ApiOptions): Promise<PagingList<Tenant>> {
    return apiService.getItems('tenants', options).then(tenants => tenants)
}

function deleteTenant(id: number) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/tenants/${id}`, requestOptions)
        .then(handleResponse);
}

function getAccountUsage(): Promise<AccountData> {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/tenants/account`, requestOptions)
        .then(handleResponse);
}

function addTenant(tenant: Tenant) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(tenant)
    }
    return fetch(`${config.apiUrl}/tenants`, requestOptions)
        .then(handleResponse)
}

function setupTenant(setupOptions: SetupOptions, tenantToken: string) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'x-authorization-token': tenantToken,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(setupOptions)
    }
    return fetch(`${config.apiUrl}/tenants/setup`, requestOptions)
        .then(handleResponse)
}

function updateTenant(tenant: TenantUpdate): Promise<Tenant> {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(tenant)
    }
    return fetch(`${config.apiUrl}/tenants`, requestOptions)
        .then(handleResponse)
}