import { CallActionData, Message, TwilioConfig, TwilioIdentity, VideoCall } from '../_types';
import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'
import { apiService } from './api.service';

export const twilioService = {
    createRoom,
    joinRoom,
    leaveRoom,
    closeRoom,
    getCalls,
    getTenantConfig,
    createTenantConfig,
    removeTenantConfig,
    getJourneyConfig,
    createJourneyConfig,
    removeJourneyConfig,
    postChatMessage,
    getChatMessage,
    addNotes,
    callAction
}

function getChatMessage(roomId: number) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/chat/room/${roomId}`, requestOptions)
        .then(handleResponse)
        .then(messages => {
            return messages.items
        });
}
function postChatMessage(messageObject: Message, roomId: number) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(messageObject)
    };
    return fetch(`${config.apiUrl}/chat/room/${roomId}`, requestOptions)
}

async function addNotes(messageObject: Message, roomId: number) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(messageObject)
    };
    await fetch(`${config.apiUrl}/video-calls/${roomId}/notes`, requestOptions)
}


function getCalls() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrlThirdParty}/twilio/calls`, requestOptions)
        .then(handleResponse)
        .then(calls => {
            if (calls && calls.length) {
                return Promise.all(calls.map(async (n: VideoCall) => { return { ...n, journey: await apiService.getItem('journey', n.journeyId) } }))
            }
            return []
        })
}

function callAction(callId: number, action: CallActionData) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({ ...action, roomId: callId })
    };
    return fetch(`${config.apiUrlThirdParty}/twilio/call-action`, requestOptions)
        .then(handleResponse);
}

function createRoom(roomId: number, identity: TwilioIdentity) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            roomId: roomId,
            identity: identity
        })
    };
    return fetch(`${config.apiUrlThirdParty}/twilio/create-call`, requestOptions)
        .then(response => handleResponse(response, true))
}

function joinRoom(roomId: number, identity: TwilioIdentity) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            roomId: roomId,
            identity: identity
        })
    };
    return fetch(`${config.apiUrlThirdParty}/twilio/join-call`, requestOptions)
        .then(response => handleResponse(response, true))
}

function leaveRoom(roomId: number, identity: TwilioIdentity) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrlThirdParty}/twilio/call/${roomId}/${identity}`, requestOptions)
        .then(handleResponse)
        .then(token => {
            return token
        })
}

function closeRoom(roomId: number) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrlThirdParty}/twilio/call/${roomId}`, requestOptions)
        .then(handleResponse)
        .then(token => {
            return token
        })
}

function getTenantConfig(tenantId: number): Promise<TwilioConfig> {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
    return fetch(`${config.apiUrlThirdParty}/twilio/tenant/${tenantId}/configure`, requestOptions)
        .then(handleResponse)
        .then(response => { return response })
}

function createTenantConfig(tenantId: number, data: TwilioConfig) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
    }
    return fetch(`${config.apiUrlThirdParty}/twilio/tenant/${tenantId}/configure`, requestOptions)
        .then(response => { return response })
}

function removeTenantConfig(tenantId: number) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
    }
    return fetch(`${config.apiUrlThirdParty}/twilio/tenant/${tenantId}/configure`, requestOptions)
        .then(response => { return response })
}
function getJourneyConfig(journeyId: number): Promise<TwilioConfig> {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
    return fetch(`${config.apiUrlThirdParty}/twilio/journey/${journeyId}/configure`, requestOptions)
        .then(response => handleResponse(response, true))
}

function createJourneyConfig(journeyId: number, data: TwilioConfig) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
    }
    return fetch(`${config.apiUrlThirdParty}/twilio/journey/${journeyId}/configure`, requestOptions)
        .then(response => { return response })
}

function removeJourneyConfig(journeyId: number) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
    }
    return fetch(`${config.apiUrlThirdParty}/twilio/journey/${journeyId}/configure`, requestOptions)
        .then(response => { return response })
}
