import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import '../../Styles/_components/_login.scss';
import { useTranslation } from 'react-i18next';
import { alertActions } from '../../_actions';


export function ResetForm(props) {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const auth = useSelector(state => state.authentication);
    const alerts = useSelector(state => state.alert);
    const [showModal, setShowModal] = useState(false);
    const [inputs, setInputs] = useState({ email: '' });
    const [submitted, setSubmitted] = useState(false);
    const { email } = inputs;

    const [alert, setAlert] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (alerts && alerts.queue.length) {
            setAlert(alerts.queue[0]);
            setShowModal(true);
            setLoading(false);
        }
    }, [alerts.queue]);

    const clearAlert = () => {
        setAlert({});
        dispatch(alertActions.clear());
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function restPassword(e) {
        e.preventDefault();
        setSubmitted(true);

        if (email) {
            dispatch(userActions.resetPassword(email))
                .then((response) => {
                    if (response.status === 401) {
                        setShowModal(true);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        setShowModal(true);
                    }
                });
        }
    }

    function handleClose() {
        setShowModal(false);
        clearAlert();
    }

    function handleOverlayClick(e) {
        if (e.target.classList.contains("modal-overlay")) {
            setShowModal(false);
        }
    }

    return <div className="login-form">
        <div className="login-logo">
            <div className='welcome'>{t("passwordReset.request-password-reset-link")}</div>
            <div className='welcome-msg'>{t("passwordReset.request-password-reset-subtitle")}</div>
        </div>
        <form className="form" onSubmit={restPassword}>
            <div className="form-group">
                <label>{t("login.email")}</label>
                <input data-testid="email" type="text" name="email" value={email} placeholder={t("passwordReset.email-placeholder")} onChange={handleChange} className={'form-control' + (submitted && !email ? ' is-invalid' : '')} />
                {submitted && !email ? <span className='required-field'>{t("passwordReset.required-field")}</span> : null}
            </div>
            <div className="form-group">
                <button data-testid="login" className="btn btn-primary">
                    {(auth && auth.loggingIn) && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    {t("passwordReset.request-reset-link")}
                </button>
            </div>
            <Link className='reset' onClick={() => props.setSection("login")}>{t("passwordReset.return-to-login")}</Link>
        </form>
        {showModal && (
            <div className="modal-overlay" onClick={handleOverlayClick}>
                <div className="confirmation-modal login" show={showModal}>
                    <div className='close-confirmation-modal close' onClick={handleClose}><i className="far fa-times"></i></div>
                    <div className="confirmation-modal-header">
                        {alert?.message?.status === 401 ? t("passwordReset.alert-email") : t("passwordReset.confirm-email")}
                    </div>
                    <div className="confirmation-message">
                        {alert?.message?.status === 401 ? t("passwordReset.alert-email-message") : t("passwordReset.confirm-email-message")}
                    </div>
                    <div className="modal-footer">
                        {alert?.message?.status === 401 ? <div className="confirmation-modal-buttons">
                            <a href='/signup' className="btn btn-primary w-50">{t("passwordReset.new-account")}</a>
                        </div> : null}
                    </div>
                </div>
            </div>
        )}
    </div>;
}


