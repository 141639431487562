import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../Styles/_components/_login.scss';
import { userService } from '../../_services';
import { useTranslation } from 'react-i18next';

export function NewPasswordForm(props) {
    const [inputs, setInputs] = useState({
        password: '',
        confirmPassword: ''
    });

    const { t } = useTranslation("common");
    let loc = useLocation();
    const accessCode = loc.search.slice(3);
    const { password, confirmPassword } = inputs;
    const [error, setError] = useState();
    const [passwordUpdated, setPasswordUpdated] = useState();
    const [submitted, setSubmitted] = useState(false);

    // State to toggle password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    function setNewPassword(e) {
        e.preventDefault();
        setSubmitted(true);
        if (password !== confirmPassword) {
            setError(t('passwordReset.no-match'));
        } else {
            if (password && accessCode) {
                userService.accessCodePasswordReset(accessCode, password).then(user => {
                    if (!user) {
                        setError(t("passwordReset.reset-expired"));
                    } else {
                        setPasswordUpdated(true);
                        setTimeout(() => {
                            props.setSection("login");
                        }, 3000);
                    }
                });
            }
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    return (
        <div className="login-form">
            <div className="login-logo">
                <div className='welcome'>{t("passwordReset.reset-password")}</div>
                <div className='welcome-msg'></div>
                {error && (
                    <div className='password-error'>
                        <span>{error}</span>
                    </div>
                )}
            </div>
            {!passwordUpdated ? (
                <form className="form" onSubmit={setNewPassword}>
                    <div className="form-group password-container">
                        <label>{t("passwordReset.enter-your-new-password")}</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            placeholder={t("passwordReset.new-password-placeholder")}
                            onChange={handleChange}
                            className={'form-control' + (submitted && !password ? ' is-invalid' : '')}
                        />
                        <span
                            className="eye-icon"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                        </span>
                        {submitted && !password && <span className='required-field'>{t("passwordReset.required-field")}</span>}
                    </div>

                    <div className="form-group password-container">
                        <label>{t("passwordReset.confirm-your-password")}</label>
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={confirmPassword}
                            placeholder={t("passwordReset.repeat-new-password-placeholder")}
                            onChange={handleChange}
                            className={'form-control' + (submitted && !confirmPassword ? ' is-invalid' : '')}
                        />
                        <span
                            className="eye-icon"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            {showConfirmPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                        </span>
                        {submitted && !confirmPassword && <span className='required-field'>{t("passwordReset.required-field")}</span>}
                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary">
                            {t("passwordReset.reset-password")}
                        </button>
                    </div>
                </form>
            ) : (
                <div>
                    <h2 className="text-center">{t("passwordReset.password-changed-successfully")}</h2>
                    <p className="text-center">{t("passwordReset.auto-return-to-login")}</p>
                    <Link className='reset text-center' onClick={() => props.setSection("login")}>
                        {t("passwordReset.return-to-login")}
                    </Link>
                </div>
            )}
        </div>
    );
}
