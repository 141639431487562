import React from 'react';
import { useDispatch } from 'react-redux';
import { LoginModalActions } from '../../../_actions/loginModal.actions';
import { useTranslation } from 'react-i18next';

export default function LoginErrorModal() {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(LoginModalActions.closeModal());
    };

    const handleOverlayClick = (e) => {
        // Close modal only if the overlay is clicked, not the modal itself
        if (e.target.classList.contains("modal-overlay")) {
            handleClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="confirmation-modal login">
                <div className='close-confirmation-modal close' onClick={handleClose}><i className="far fa-times"></i></div>
                <div className="confirmation-modal-header">{t("modals.loginError.header")}</div>
                <div className="confirmation-message">
                    {t("modals.loginError.message")}
                </div>
                <div className="modal-footer">
                    <div className="confirmation-modal-buttons">
                        <a href='/reset' className="btn btn-primary w-100">
                            {t("login.reset-password")}
                        </a>
                        <a onClick={handleClose} className="btn btn-primary w-100">
                            {t("login.try-again")}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
